import styled from 'styled-components';
import { media } from '@pik/pik-ui';

export const Container = styled.div`
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid #E2E6E8;
  padding: 28px 32px 29px 32px;
  width: 352px;
  height: 224px;
  cursor: pointer;
  transition: border-color .4s ease;
  text-decoration: none;
  border-radius: 4px;
  
  @media ${media.max1120} {
    width: 100%;
    margin-top: 16px;
    
  }
  
  &:hover {
    border-color: #d6d6d6;
  }
`;

export const Title = styled.span`
  display: block;
  font-family: Graphik;
  font-weight: 500;
  font-size: 20px;
  color: #222222;
  line-height: 28px;
  max-height: 92px;
  margin-bottom: 12px;
  overflow: hidden;
  
  @media ${media.max768} {
    font-size: 18px;
    line-height: 20px;
  }
`;

export const Text = styled.span`
  display: block;
  max-height: 60px;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  color: #808080;
`;


export const Info = styled.span`
  font-size: 11px;
  color: #808080;
`;

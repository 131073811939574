import React from 'react';

import Router from 'next/router';

import {
  ButtonContainer,
} from './styles';

// const handleClick = ({ onClick, link }) => {
//   if (link) {
//     Router.push(link);
//     return window.scrollTo(0, 0);
//   }
//   onClick();
// };

const Button = ({
  children, ...props
}) => (
  <ButtonContainer onClick={() => {}} {...props}>
    {children}
  </ButtonContainer>
);

export default Button;

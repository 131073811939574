import styled from 'styled-components';
import { media } from '@pik/pik-ui';

export const ButtonContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Graphik;
  font-weight: 500;
  font-size: 12px;
  color: #4D4D4D;
  letter-spacing: 0;
  text-align: center;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  transition: color .3s ease, border-color .3s ease, background .3s ease;
  cursor: pointer;
  padding: 0 22px;
  height: 48px;
  text-decoration: none;
  
  ${({ block }) => block && 'width: 100%;'}
  ${({ medium }) => medium && 'height: 40px;'}
  ${({ small }) => small && `
    height: 36px;
    padding: 0px 24px;
  `}
  
  ${({ large }) => large && `
    height: 56px;
    padding: 0 58px;
  `}
  
  &:hover {
    color: #222222;
    border-color: #222222;
  }
  
  ${({ secondary }) => secondary && `
    background: #26334D;
    color: #FFFFFF;
    border: 1px solid transparent;

    &:hover {
      background: #0F1B33;
      color: #FFFFFF;
      border-color: transparent;
    }
  `}
  
  ${({ disabled }) => disabled && `
    background: #F2F2F2;
    color: #CCCCCC;
    pointer-events: none;
    
    &:hover {
      background: #F2F2F2;
      color: #CCCCCC;
    }
  `}

  @media ${media.max768} {
    max-width: 160px;
  }
`;

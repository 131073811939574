import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Link from '../Link';

import {
  Container,
  Title,
  Text,
  Info,
} from './styles';

export const propTypes = {
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

const NewsPreview = ({
  title, excerpt, id, publishedAt, lang
}) => {
  const isEng = lang === 'eng';
  let newsDate;

  if (isEng) {
    dayjs.locale('en');
    newsDate = dayjs.unix(publishedAt).format('DD MMMM, YYYY').toLowerCase();
  } else {
    dayjs.locale('ru');
    newsDate = dayjs.unix(publishedAt).format('DD MMMM YYYY').toLowerCase();
  }

  return (
    <Link href={`/about/news-and-reports/news/${id}`}>
      <Container>
        <div>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <Text dangerouslySetInnerHTML={{ __html: excerpt }} />
        </div>
        <Info>{newsDate}</Info>
      </Container>
    </Link>
  );
};

NewsPreview.propTypes = {
  ...propTypes,
};

const mapStateToProps = ({ appService }) => ({
  lang: appService.lang,
});

export default connect(mapStateToProps)(NewsPreview);
